import {
    CellsWidth,
    EntryCalcHeadersWithItems,
    EntryCalculationItemExtended,
    EntryCalculationItemModel,
    HiddenCols,
    PriceChangeLogHeaderWithItems,
    PriceChangeLogItemModel,
    PriceChangeLogItemsExtended,
    SheetChange,
    StockDocHeadersWithItems,
    StockDocItemModel,
    StockDocItemsExtended,
    StockDocumentTypesEnum,
    StockHeaderEnums,
    StockKeepingUnitEditModel,
    StockKeepingUnitModel,
    TaxModel,
} from '@common/modelDefinition';
import translate from '@data/translations';
import { toast } from 'react-toastify';
import { getPrevOrNextDocumentOrEntryCalc, saveGeneralSettings } from './api';
//@ts-ignore
import _ from 'lodash';
import useStore from '@data/state/zustand';

interface StockControllerInterface {
    checkValidationInputs(objToValidate: any, getValidationInputs: Function): void;
    markInvalidInputRefs(validationResults: any): void;
    //zajednicke funkcije za dokumente i kalkulaciju
    getInputHeaderId(SelectedHeadersEnums: StockHeaderEnums, input: string): number;
    getSelectedHeaders(hiddenCols: string[] | null, type: string, SelectedHeadersEnums: StockHeaderEnums): number[];
    remakeHeaders(
        selectedHeaders: number[],
        SelectedHeadersEnums: StockHeaderEnums,
        CellsWitdh: CellsWidth,
        setCellWidth: Function,
        setHeaders: Function,
        setHeadersEnums: Function
    ): void;
    selectCells(value: number, selectedHeaders: number[]): number[];
    saveHiddenCols(
        SelectedHeadersEnums: StockHeaderEnums,
        selectedHeaders: number[],
        type: number | string | null,
        hiddenCols: HiddenCols,
        document: boolean
    ): Promise<void>;
    pageChange(
        history: History,
        type: string,
        stockDocument: StockDocHeadersWithItems | null,
        entryCalc: EntryCalcHeadersWithItems | null
    ): Promise<void>;
    changeCodeOrBarcodeCell(
        data: any,
        change: SheetChange,
        HeadersEnums: StockHeaderEnums,
        document: boolean
    ): Promise<any[]>;
    //za dokumente
    makeStockDocItemModel(
        data: any,
        taxes: TaxModel[],
        _HeaderEnums: StockHeaderEnums,
        selectedSkuItem: StockKeepingUnitModel
    ): StockDocItemModel;
    makeDataForTableForDocuments(
        stockDocItems: StockDocItemsExtended[],
        taxes: TaxModel[],
        setData: Function,
        type: number,
        _HeaderEnums: StockHeaderEnums
    ): void;
    findTaxId(taxName: string, taxes: TaxModel[]): TaxModel | null;
    getTaxForCell(
        change: SheetChange,
        SelectedHeadersEnums: StockHeaderEnums,
        taxes: TaxModel[],
        taxCell: string
    ): TaxModel | null;
    returnEditStockDocItemValue(
        change: SheetChange,
        SelectedHeadersEnums: StockHeaderEnums,
        taxes: TaxModel[]
    ): number | string | null;
    getReadOnlyDocProps(SelectedHeadersEnums: StockHeaderEnums, type: number): number[];
    //za kalkulaciju
    makeEntryCalcItemModel(data: any): EntryCalculationItemModel;
    getReadOnlyProps(SelectedHeadersEnums: StockHeaderEnums): number[];
    makeDataForTableForCalculation(
        entryCalcItems: EntryCalculationItemExtended[],
        setTotalItemsEntryPrice: Function,
        setData: Function
    ): void;
    recalculateEditedItems(
        data: any,
        editedObj: EntryCalculationItemModel,
        SelectedHeadersEnums: StockHeaderEnums
    ): EntryCalculationItemModel;
    haveCurrencyorPercentageIndexCalculation(SelectedHeadersEnums: StockHeaderEnums): {
        haveCurrencyIndex: number[];
        havePercentageIndex: number[];
    };
    //on change
    onChangeDocumentCells(
        changes: SheetChange[],
        data: any[],
        stockDocItems: StockDocItemsExtended[],
        _editedStockDocItems: { [key: string]: StockDocItemsExtended },
        SelectedHeadersEnums: StockHeaderEnums,
        taxes: TaxModel[],
        _HeaderEnums: StockHeaderEnums,
        selectValue?: string | number
    ): Promise<any>;
    onChangeCalculationCell(
        changes: SheetChange[],
        data: any[],
        entryCalcItems: EntryCalculationItemExtended[],
        SelectedHeadersEnums: StockHeaderEnums,
        selectValue?: number
    ): Promise<any>;
}

class StockController implements StockControllerInterface {
    checkValidationInputs(objToValidate: any, getValidationInputs: Function) {
        let validationResults = getValidationInputs(objToValidate);
        this.markInvalidInputRefs(validationResults);
    }

    markInvalidInputRefs(validationResults: any) {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        invalidInputs.forEach((input: any) => {
            if (input && input.current) {
                let classes = input.current.className ? input.current.className?.split(' ') : [];
                if (!classes?.includes('is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        validInputs.forEach((input: any) => {
            if (input && input.current) {
                let classes = input.current.className ? input.current.className?.split(' ') : [];
                if (classes?.includes('is-invalid')) {
                    _.remove(classes, (i: any) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    }

    getInputHeaderId(SelectedHeadersEnums: StockHeaderEnums, input: string): number {
        return Number(Object.keys(SelectedHeadersEnums).find((k: string) => SelectedHeadersEnums[Number(k)] === input));
    }

    getSelectedHeaders(hiddenCols: string[] | null, type: string, SelectedHeadersEnums: StockHeaderEnums): number[] {
        const selectedHeaders: any[] = hiddenCols
            ? Object.keys(SelectedHeadersEnums)
                  .map((h: string) => (!hiddenCols?.includes(SelectedHeadersEnums[Number(h)]) ? Number(h) : null))
                  .filter((h) => h !== null)
            : Object.keys(SelectedHeadersEnums)
                  .map((h) => {
                      if (type === 'documents') {
                          const hidden = [
                              'id',
                              'taxId2',
                              'taxId3',
                              'skuId',
                              'internalSkuCode',
                              'internalSkuBarcode',
                              'skuItemName',
                          ];
                          if (hidden?.includes(SelectedHeadersEnums[Number(h)])) {
                              return null;
                          } else {
                              return Number(h);
                          }
                      } else if (type === 'stockReport') {
                          if (
                              SelectedHeadersEnums[Number(h)] !== 'skuId' &&
                              SelectedHeadersEnums[Number(h)] !== 'barcode' &&
                              SelectedHeadersEnums[Number(h)] !== 'code' &&
                              SelectedHeadersEnums[Number(h)] !== 'stockDocItemId' &&
                              SelectedHeadersEnums[Number(h)] !== 'taxId1'
                          ) {
                              return Number(h);
                          } else {
                              return null;
                          }
                      } else if (type === 'calculation') {
                          const hidden = [
                              'id',
                              'stockMeasureUnit',
                              'entryVATInfo',
                              'tax1Total',
                              'tax2Total',
                              'tax3Total',
                              'skuId',
                              'barcode',
                              'code',
                              'name',
                          ];
                          if (hidden?.includes(SelectedHeadersEnums[Number(h)])) {
                              return null;
                          } else {
                              return Number(h);
                          }
                      } else {
                          return Number(h);
                      }
                  })
                  .filter((h) => h !== null);

        return selectedHeaders;
    }

    remakeHeaders(
        selectedHeaders: number[],
        SelectedHeadersEnums: StockHeaderEnums,
        CellsWitdh: CellsWidth,
        setCellWidth: Function,
        setHeaders: Function,
        setHeadersEnums: Function
    ): void {
        const _cellsWidth = Object.keys(CellsWitdh)
            .map((k: string) => (selectedHeaders.includes(Number(k)) ? CellsWitdh[Number(k)] : null))
            .filter((k) => k !== null);
        setCellWidth([..._cellsWidth]);

        const _headers = Object.keys(SelectedHeadersEnums)
            .map((h: string) => (selectedHeaders.includes(Number(h)) ? SelectedHeadersEnums[Number(h)] : null))
            .filter((h) => h !== null);
        setHeaders(_headers);

        const hEnums: any = {};
        _headers.forEach((h, index) => {
            hEnums[Number(index)] = h;
        });
        setHeadersEnums({ ...hEnums });
    }

    selectCells(value: number, selectedHeaders: number[]): number[] {
        let _selectedHeaders = [...selectedHeaders];
        if (_selectedHeaders.includes(value)) {
            _selectedHeaders = _selectedHeaders.filter((s) => s !== value);
        } else {
            _selectedHeaders.push(value);
        }
        return _selectedHeaders;
    }

    async saveHiddenCols(
        SelectedHeadersEnums: StockHeaderEnums,
        selectedHeaders: number[],
        type: number | string | null,
        hiddenCols: HiddenCols,
        document: boolean
    ): Promise<void> {
        const unSelectedHeaders: string[] = [];
        Object.keys(SelectedHeadersEnums).forEach((h: string) => {
            if (!selectedHeaders.includes(Number(h))) {
                unSelectedHeaders.push(SelectedHeadersEnums[Number(h)]);
            }
        });
        try {
            const _hiddenDocCol = hiddenCols ? { ...hiddenCols } : {};
            if (type && typeof type === 'number' && document) {
                const docType = StockDocumentTypesEnum[type];
                _hiddenDocCol[docType] = unSelectedHeaders;
            } else if (type === null && document) {
                //ovdje ne bi trebao nikad uci
                _hiddenDocCol['stockDocuments'] = unSelectedHeaders;
            } else if (type && !document) {
                _hiddenDocCol[type] = unSelectedHeaders;
            }
            const params = JSON.stringify(_hiddenDocCol);
            const obj = {
                key: 'hiddenDocumentColumns',
                params: params,
            };
            const res = await saveGeneralSettings(obj);
            useStore.getState().setNestedProperty(['hiddenDocumentColumns'], JSON.parse(res.params));
        } catch (error: any) {
            toast(error.message, { type: toast.TYPE.ERROR });
        }
    }

    async pageChange(
        history: any,
        type: string,
        stockDocument: StockDocHeadersWithItems | null,
        entryCalc: EntryCalcHeadersWithItems | null
    ) {
        if (stockDocument?.id) {
            const prevOrNextDoc = await getPrevOrNextDocumentOrEntryCalc(type, stockDocument.id, true);
            if (Number(prevOrNextDoc)) {
                history.push('/goodsManagement/documents/edit/' + prevOrNextDoc);
            }
        } else if (entryCalc?.id) {
            const prevOrNextCalc = await getPrevOrNextDocumentOrEntryCalc(type, entryCalc.id);
            if (Number(prevOrNextCalc)) {
                history.push('/goodsManagement/entryCalc/edit/' + prevOrNextCalc);
            }
        }
    }

    changeCodeOrBarcodeCell(
        data: any,
        change: SheetChange,
        HeadersEnums: StockHeaderEnums,
        document: boolean,
        taxes?: TaxModel[]
    ): Promise<any[]> {
        const newData = [...data];
        if (change?.skuItem?.active) {
            newData[change.y][this.getInputHeaderId(HeadersEnums, 'skuId')] = change?.skuItem.id;
            newData[change.y][this.getInputHeaderId(HeadersEnums, 'skuItem')] =
                change?.skuItem.name + (change?.skuItem?.code ? ' (' + change.skuItem.code + ')' : '');
            if (document) {
                newData[change.y][this.getInputHeaderId(HeadersEnums, 'stockMeasureUnit')] =
                    change?.skuItem.stockMeasureUnit;
                newData[change.y][this.getInputHeaderId(HeadersEnums, 'skuPrice')] = change?.skuItem.salesPrice;
                newData[change.y][this.getInputHeaderId(HeadersEnums, 'purchaseMeasureUnit')] =
                    change?.skuItem.purchaseMeasureUnit;
                newData[change.y][this.getInputHeaderId(HeadersEnums, 'purchaseMeasureUnit_stockUnitMultiplier')] =
                    change?.skuItem.purchaseMeasureUnit_stockUnitMultiplier;

                const tax1 = taxes?.find((t) => t.id === change?.skuItem?.taxId1);
                const tax2 = taxes?.find((t) => t.id === change?.skuItem?.taxId2);
                const tax3 = taxes?.find((t) => t.id === change?.skuItem?.taxId3);
                if (
                    change?.x === this.getInputHeaderId(HeadersEnums, 'taxId1') ||
                    change?.x === this.getInputHeaderId(HeadersEnums, 'taxId2') ||
                    change?.x === this.getInputHeaderId(HeadersEnums, 'taxId3')
                ) {
                    newData[change.y][this.getInputHeaderId(HeadersEnums, 'taxId1')] = tax1?.name;
                    newData[change.y][this.getInputHeaderId(HeadersEnums, 'taxId2')] = tax2?.name;
                    newData[change.y][this.getInputHeaderId(HeadersEnums, 'taxId3')] = tax3?.name;
                }

                if (this.getInputHeaderId(HeadersEnums, 'priceForOfferNote')) {
                    newData[change.y][this.getInputHeaderId(HeadersEnums, 'priceForOfferNote')] =
                        change?.skuItem.salesPrice;
                }
            }
            return newData[change.y];
        } else {
            throw new Error(translate('Item is not active!'));
        }
    }

    // ZA DOKUMENTE

    makeStockDocItemModel(
        data: any,
        taxes: TaxModel[],
        _HeaderEnums: StockHeaderEnums,
        selectedSkuItem: StockKeepingUnitModel
    ): StockDocItemModel {
        const taxId1 = this.findTaxId(data[this.getInputHeaderId(_HeaderEnums, 'taxId1')], taxes);
        const taxId2 = this.findTaxId(data[this.getInputHeaderId(_HeaderEnums, 'taxId2')], taxes);
        const taxId3 = this.findTaxId(data[this.getInputHeaderId(_HeaderEnums, 'taxId3')], taxes);

        const stockDocItem: StockDocItemModel = {
            skuId: data[this.getInputHeaderId(_HeaderEnums, 'skuId')],
            quantity: Number(data[this.getInputHeaderId(_HeaderEnums, 'stockUnitQuantity')])
                ? Number(data[this.getInputHeaderId(_HeaderEnums, 'stockUnitQuantity')])
                : 0,
            purchasePrice:
                this.getInputHeaderId(_HeaderEnums, 'purchaseUnitPrice') &&
                Number(data[this.getInputHeaderId(_HeaderEnums, 'purchaseUnitPrice')])
                    ? data[this.getInputHeaderId(_HeaderEnums, 'purchaseUnitPrice')]
                    : 0,
            taxId1: taxId1?.id ? taxId1.id : null,
            taxId2: taxId2?.id ? taxId2.id : null,
            taxId3: taxId3?.id ? taxId3.id : null,
            skuPrice:
                this.getInputHeaderId(_HeaderEnums, 'skuPrice') &&
                Number(data[this.getInputHeaderId(_HeaderEnums, 'skuPrice')])
                    ? Number(data[this.getInputHeaderId(_HeaderEnums, 'skuPrice')])
                    : 0,
            priceForOfferNote: Number(data[this.getInputHeaderId(_HeaderEnums, 'priceForOfferNote')])
                ? Number(data[this.getInputHeaderId(_HeaderEnums, 'priceForOfferNote')])
                : 0,
            skuName: selectedSkuItem?.name ? selectedSkuItem?.name : '',
            lockedItem: false,
            rebatePercentage:
                this.getInputHeaderId(_HeaderEnums, 'rebatePercentage') &&
                Number(data[this.getInputHeaderId(_HeaderEnums, 'rebatePercentage')])
                    ? Number(data[this.getInputHeaderId(_HeaderEnums, 'rebatePercentage')])
                    : 0,
        };
        return stockDocItem;
    }

    makeStockDocItemModelBySelectedSkuItems(selectedSkuItem: StockKeepingUnitEditModel): StockDocItemModel {
        const stockDocItem: StockDocItemModel = {
            skuId: selectedSkuItem?.id,
            quantity: 0,
            purchasePrice: 0,
            taxId1: selectedSkuItem?.taxId1,
            taxId2: selectedSkuItem?.taxId2,
            taxId3: selectedSkuItem?.taxId3,
            skuPrice: selectedSkuItem?.salesPrice,
            priceForOfferNote: selectedSkuItem?.salesPrice,
            skuName: selectedSkuItem?.name ? selectedSkuItem?.name : '',
            lockedItem: false,
            parentId: selectedSkuItem?.parentId,
        };
        return stockDocItem;
    }

    makeDataForTableForDocuments(
        stockDocItems: StockDocItemsExtended[],
        taxes: TaxModel[],
        setData: Function,
        type: number,
        _HeaderEnums: StockHeaderEnums,
        stockDocument?: StockDocHeadersWithItems
    ) {
        const _data: any = [];
        stockDocItems.forEach((r: StockDocItemsExtended) => {
            const taxId1 = taxes?.find((t) => t.id === r.taxId1);
            const taxId2 = taxes?.find((t) => t.id === r.taxId2);
            const taxId3 = taxes?.find((t) => t.id === r.taxId3);

            let row = [];
            row.push(r.id);
            row.push(
                r.StockKeepingUnit?.name
                    ? r.StockKeepingUnit?.name +
                          (r.StockKeepingUnit?.code ? ' (' + r.StockKeepingUnit?.code + ')' : '') +
                          (r.StockKeepingUnit?.stockMeasureUnit ? ' ' + r.StockKeepingUnit?.stockMeasureUnit : '')
                    : '' + (r.StockKeepingUnit?.code ? ' (' + r.StockKeepingUnit?.code + ')' : '')
            );

            if (type === StockDocumentTypesEnum.goodsReceivingNote) {
                const purhcasePriceValue =
                    (r.quantity ? Number(r.quantity) : 0) *
                    (r.purchasePrice ? Number(Number(r.purchasePrice).toFixed(2)) : 0);
                const taxTotal =
                    (Number(taxId1?.percentageAmount ? taxId1?.percentageAmount : 0) / 100) *
                        Number(purhcasePriceValue) +
                    (Number(taxId2?.percentageAmount ? taxId2?.percentageAmount : 0) / 100) *
                        Number(purhcasePriceValue) +
                    (Number(taxId3?.percentageAmount ? taxId3?.percentageAmount : 0) / 100) *
                        Number(purhcasePriceValue);

                row.push(r.StockKeepingUnit?.stockMeasureUnit ? r.StockKeepingUnit?.stockMeasureUnit : '');
                row.push(r.StockKeepingUnit?.purchaseMeasureUnit ? r.StockKeepingUnit?.purchaseMeasureUnit : '');
                row.push(
                    r.StockKeepingUnit?.purchaseMeasureUnit_stockUnitMultiplier
                        ? Number(r.StockKeepingUnit?.purchaseMeasureUnit_stockUnitMultiplier).toFixed(2)
                        : Number(1).toFixed(2)
                );
                row.push(r?.StockKeepingUnit?.salesPrice ? Number(r.StockKeepingUnit?.salesPrice).toFixed(2) : '');
                row.push(r.quantity ? r.quantity : 0);
                row.push(
                    r.StockKeepingUnit?.purchaseMeasureUnit &&
                        r.StockKeepingUnit?.purchaseMeasureUnit_stockUnitMultiplier
                        ? Number(r.quantity / r.StockKeepingUnit?.purchaseMeasureUnit_stockUnitMultiplier).toFixed(2)
                        : 0
                );
                row.push(r.purchasePrice ? Number(r.purchasePrice).toFixed(2) : 0);
                row.push(Number(purhcasePriceValue)?.toFixed(2));
                row.push(r.rebatePercentage ? Number(r.rebatePercentage) : 0);
                row.push((Number(Number(r.rebatePercentage) / 100) * Number(purhcasePriceValue))?.toFixed(2));
                row.push(r.taxId1 && taxId1 ? taxId1?.name : '');
                row.push(r.taxId2 && taxId2 ? taxId2.name : '');
                row.push(r.taxId3 && taxId3 ? taxId3.name : '');
                row.push(Number(taxTotal)?.toFixed(2));
                row.push(Number(Number(purhcasePriceValue) + Number(taxTotal))?.toFixed(2));
                row.push(r.skuId);
            } else if (type === StockDocumentTypesEnum.goodsDespatchNote) {
                row.push(r.StockKeepingUnit?.stockMeasureUnit ? r.StockKeepingUnit?.stockMeasureUnit : '');
                row.push(r?.StockKeepingUnit?.salesPrice ? Number(r.StockKeepingUnit?.salesPrice).toFixed(2) : '');
                row.push(r.quantity ? r.quantity : 0);
                row.push(taxId1?.name ? taxId1.name : '');
                row.push(taxId2?.name ? taxId2.name : '');
                row.push(taxId3?.name ? taxId3.name : '');
                row.push(r.skuId);
            } else if (
                type === StockDocumentTypesEnum.writeOffGoods ||
                type === StockDocumentTypesEnum.invoicedStockNote ||
                (type === StockDocumentTypesEnum.goodsReceivingNote && r.invoiceItemId)
            ) {
                row.push(r?.StockKeepingUnit?.salesPrice ? Number(r.StockKeepingUnit?.salesPrice).toFixed(2) : '');
            } else if (type === StockDocumentTypesEnum.procurementOrder) {
                row.push(r.externalCode ? r.externalCode : '');
                row.push(r.purchasePrice ? Number(r.purchasePrice).toFixed(4) : '');
            } else if (type === StockDocumentTypesEnum.offerNote) {
                const salesPriceValue = Number(
                    (r.quantity ? Number(r.quantity) : 0) *
                        (r.StockKeepingUnit?.salesPrice ? Number(Number(r.StockKeepingUnit?.salesPrice).toFixed(2)) : 0)
                )?.toFixed(2);
                const taxTotal =
                    (Number(taxId1?.percentageAmount ? taxId1?.percentageAmount : 0) / 100) * Number(salesPriceValue) +
                    (Number(taxId2?.percentageAmount ? taxId2?.percentageAmount : 0) / 100) * Number(salesPriceValue) +
                    (Number(taxId3?.percentageAmount ? taxId3?.percentageAmount : 0) / 100) * Number(salesPriceValue);

                row.push(r.StockKeepingUnit?.stockMeasureUnit ? r.StockKeepingUnit?.stockMeasureUnit : '');
                row.push(r?.StockKeepingUnit?.salesPrice ? Number(r.StockKeepingUnit?.salesPrice).toFixed(2) : '');
                row.push(r.quantity ? r.quantity : 0);
                row.push(r.priceForOfferNote ? Number(r.priceForOfferNote)?.toFixed(2) : '');
                row.push(Number(salesPriceValue));
                row.push(r.rebatePercentage ? Number(r.rebatePercentage) : 0);
                row.push((Number(Number(r.rebatePercentage) / 100) * Number(salesPriceValue))?.toFixed(2));
                row.push(taxId1?.name ? taxId1.name : '');
                row.push(taxId2?.name ? taxId2.name : '');
                row.push(taxId3?.name ? taxId3.name : '');
                row.push(Number(taxTotal)?.toFixed(2));
                row.push(r.skuId);
            }

            if (
                type === StockDocumentTypesEnum.writeOffGoods ||
                type === StockDocumentTypesEnum.invoicedStockNote ||
                type === StockDocumentTypesEnum.interWarehouseTransfer ||
                type === StockDocumentTypesEnum.procurementOrder ||
                (type === StockDocumentTypesEnum.goodsReceivingNote && r.invoiceItemId)
            ) {
                row.push(r.quantity ? r.quantity : 0);
                row.push(r.skuId);
            }

            _data.push(row);
        });

        if (stockDocument?.locked) {
            const lastRow = this.getDataTotalValues(_data, _HeaderEnums);
            _data.push(lastRow);
        }

        setData([..._data]);
    }

    getDataTotalValues(_data: any, _HeaderEnums: StockHeaderEnums): any {
        const lastRow = [];
        Object.keys(_HeaderEnums)?.forEach((h) => (lastRow[Number(h)] = ''));

        let skuPrice = 0,
            stockUnitQuantity = 0,
            piecesQuantity = 0,
            purchaseUnitPrice = 0,
            purchaseValueStockUnit = 0,
            salesPriceValue = 0,
            rebateTotal = 0,
            taxTotal = 0;
        for (let i = 0; i < _data?.length; i++) {
            const d = _data[i];
            skuPrice = skuPrice + Number(d[this.getInputHeaderId(_HeaderEnums, 'skuPrice')]);
            stockUnitQuantity = stockUnitQuantity + Number(d[this.getInputHeaderId(_HeaderEnums, 'stockUnitQuantity')]);
            piecesQuantity = piecesQuantity + Number(d[this.getInputHeaderId(_HeaderEnums, 'piecesQuantity')]);
            purchaseUnitPrice = purchaseUnitPrice + Number(d[this.getInputHeaderId(_HeaderEnums, 'purchaseUnitPrice')]);
            purchaseValueStockUnit =
                purchaseValueStockUnit + Number(d[this.getInputHeaderId(_HeaderEnums, 'purchaseValueStockUnit')]);
            salesPriceValue = salesPriceValue + Number(d[this.getInputHeaderId(_HeaderEnums, 'salesPriceValue')]);
            rebateTotal = rebateTotal + Number(d[this.getInputHeaderId(_HeaderEnums, 'rebateTotal')]);
            taxTotal = taxTotal + Number(d[this.getInputHeaderId(_HeaderEnums, 'taxTotal')]);
        }
        lastRow[this.getInputHeaderId(_HeaderEnums, 'skuPrice')] = Number(skuPrice)?.toFixed(2);
        lastRow[this.getInputHeaderId(_HeaderEnums, 'stockUnitQuantity')] = Number(stockUnitQuantity)?.toFixed(2);
        lastRow[this.getInputHeaderId(_HeaderEnums, 'piecesQuantity')] = Number(piecesQuantity)?.toFixed(2);
        lastRow[this.getInputHeaderId(_HeaderEnums, 'purchaseUnitPrice')] = Number(purchaseUnitPrice)?.toFixed(2);
        lastRow[this.getInputHeaderId(_HeaderEnums, 'purchaseValueStockUnit')] =
            Number(purchaseValueStockUnit)?.toFixed(2);
        lastRow[this.getInputHeaderId(_HeaderEnums, 'salesPriceValue')] = Number(salesPriceValue)?.toFixed(2);
        lastRow[this.getInputHeaderId(_HeaderEnums, 'rebateTotal')] = Number(rebateTotal)?.toFixed(2);
        lastRow[this.getInputHeaderId(_HeaderEnums, 'taxTotal')] = Number(taxTotal)?.toFixed(2);

        return lastRow;
    }

    getTotalValuesForPrint(stockDocItems: StockDocItemsExtended[], SelectedHeadersEnums: any, taxes: TaxModel[]) {
        let skuPrice = 0,
            stockUnitQuantity = 0,
            piecesQuantity = 0,
            purchaseUnitPrice = 0,
            purchaseValueStockUnit = 0,
            salesPriceValue = 0,
            rebateTotal = 0,
            taxTotal = 0,
            totalPriceValue = 0,
            priceForOfferNote = 0,
            purchaseValueWithTax = 0;

        const priceForOfferNoteCell = Object.keys(SelectedHeadersEnums).find(
            (h) => SelectedHeadersEnums[Number(h)] === 'priceForOfferNote'
        );
        const purchasePriceCell = Object.keys(SelectedHeadersEnums).find(
            (h) =>
                SelectedHeadersEnums[Number(h)] === 'purchaseUnitPrice' ||
                SelectedHeadersEnums[Number(h)] === 'purchasePrice'
        );

        for (let i = 0; i < stockDocItems.length; i++) {
            const d = stockDocItems[i];

            const tax1 = taxes?.find((t) => t.id === d.taxId1);
            const tax2 = taxes?.find((t) => t.id === d.taxId2);
            const tax3 = taxes?.find((t) => t.id === d.taxId3);

            const _totalPriceValue = priceForOfferNoteCell
                ? Number(d.priceForOfferNote ? d.priceForOfferNote : 0) * d.quantity
                : purchasePriceCell
                ? Number(d.purchasePrice ? d.purchasePrice : 0) * d.quantity
                : Number(d.skuPrice ? d.skuPrice : 0) * d.quantity;
            const rebateTotalValue = (Number(d?.rebatePercentage) / 100) * Number(_totalPriceValue);
            const taxTotalValue =
                (Number(tax1?.percentageAmount ? tax1?.percentageAmount : 0) / 100) * Number(_totalPriceValue) +
                (Number(tax2?.percentageAmount ? tax2?.percentageAmount : 0) / 100) * Number(_totalPriceValue) +
                (Number(tax3?.percentageAmount ? tax3?.percentageAmount : 0) / 100) * Number(_totalPriceValue);

            skuPrice = skuPrice + Number(d?.skuPrice);
            stockUnitQuantity = stockUnitQuantity + Number(d?.quantity);
            piecesQuantity = piecesQuantity + Number(d?.quantity);
            purchaseUnitPrice = purchaseUnitPrice + Number(d?.purchasePrice);
            purchaseValueStockUnit = purchaseValueStockUnit + Number(d?.quantity) * Number(d?.purchasePrice);
            salesPriceValue = salesPriceValue + Number(d?.quantity) * Number(d?.skuPrice);
            rebateTotal = rebateTotal + Number(rebateTotalValue);
            taxTotal = taxTotal + Number(taxTotalValue);
            totalPriceValue = totalPriceValue + Number(_totalPriceValue);
            priceForOfferNote = priceForOfferNote + Number(d.priceForOfferNote ? d.priceForOfferNote : 0);
            purchaseValueWithTax = purchaseValueWithTax + Number(Number(d?.quantity) * Number(d?.purchasePrice) + Number(taxTotalValue))
        }
        const totalValues = {
            skuPrice: Number(skuPrice)?.toFixed(2),
            stockUnitQuantity: Number(stockUnitQuantity)?.toFixed(2),
            piecesQuantity: Number(piecesQuantity)?.toFixed(2),
            purchaseUnitPrice: Number(purchaseUnitPrice)?.toFixed(2),
            purchaseValueStockUnit: Number(purchaseValueStockUnit)?.toFixed(2),
            salesPriceValue: Number(salesPriceValue)?.toFixed(2),
            rebateTotal: Number(rebateTotal)?.toFixed(2),
            taxTotal: Number(taxTotal)?.toFixed(2),
            totalPriceValue: Number(totalPriceValue)?.toFixed(2),
            totalPriceForOfferNote: Number(priceForOfferNote)?.toFixed(2),
            purchaseValueWithTax: Number(purchaseValueWithTax)?.toFixed(2)
        };

        return totalValues;
    }

    findTaxId(taxName: string, taxes: TaxModel[]): TaxModel | null {
        const tax = taxName && taxName !== '' ? taxes?.find((t) => t.name === taxName) : null;
        return tax ? tax : null;
    }

    getTaxForCell(
        change: SheetChange,
        SelectedHeadersEnums: StockHeaderEnums,
        taxes: TaxModel[],
        taxCell: string
    ): TaxModel | null {
        const tax =
            taxCell === 'taxId1' || taxCell === 'taxId2' || taxCell === 'taxId3' || taxCell === 'rebatePercentage'
                ? Number(change.x) ===
                      Number(
                          this.getInputHeaderId(
                              SelectedHeadersEnums,
                              taxCell === 'taxId1'
                                  ? 'taxId1'
                                  : taxCell === 'taxId2'
                                  ? 'taxId2'
                                  : taxCell === 'taxId3'
                                  ? 'taxId3'
                                  : 'rebatePercentage'
                          )
                      ) && typeof change.value === 'string'
                    ? this.findTaxId(change.value, taxes)
                        ? this.findTaxId(change.value, taxes)
                        : null
                    : null
                : null;
        return tax;
    }

    returnEditStockDocItemValue(
        change: SheetChange,
        SelectedHeadersEnums: StockHeaderEnums,
        taxes: TaxModel[]
    ): number | string | null {
        const tax1 = this.getTaxForCell(change, SelectedHeadersEnums, taxes, 'taxId1');
        const tax2 = this.getTaxForCell(change, SelectedHeadersEnums, taxes, 'taxId2');
        const tax3 = this.getTaxForCell(change, SelectedHeadersEnums, taxes, 'taxId3');

        const itemValue =
            Number(change.x) === Number(this.getInputHeaderId(SelectedHeadersEnums, 'stockUnitQuantity'))
                ? Number(change.value)
                : Number(change.x) === Number(this.getInputHeaderId(SelectedHeadersEnums, 'taxId1'))
                ? tax1?.id
                    ? tax1.id
                    : null
                : Number(change.x) === Number(this.getInputHeaderId(SelectedHeadersEnums, 'taxId2'))
                ? tax2?.id
                    ? tax2.id
                    : null
                : Number(change.x) === Number(this.getInputHeaderId(SelectedHeadersEnums, 'taxId3'))
                ? tax3?.id
                    ? tax3.id
                    : null
                : Number(change.x) === Number(this.getInputHeaderId(SelectedHeadersEnums, 'rebatePercentage'))
                ? Number(change.value)
                : change.value;
        return itemValue;
    }

    getReadOnlyDocProps(SelectedHeadersEnums: StockHeaderEnums, type: number) {
        const readOnlyArray = [
            this.getInputHeaderId(SelectedHeadersEnums, 'id'),
            this.getInputHeaderId(SelectedHeadersEnums, 'skuPrice'),
            this.getInputHeaderId(SelectedHeadersEnums, 'skuId'),
            this.getInputHeaderId(SelectedHeadersEnums, 'stockMeasureUnit'),
            this.getInputHeaderId(SelectedHeadersEnums, 'purchaseMeasureUnit'),
            this.getInputHeaderId(SelectedHeadersEnums, 'purchaseMeasureUnit_stockUnitMultiplier'),
            this.getInputHeaderId(SelectedHeadersEnums, 'purchaseValueStockUnit'),
            this.getInputHeaderId(SelectedHeadersEnums, 'externalSkuCode'),
            this.getInputHeaderId(SelectedHeadersEnums, 'salesPriceValue'),
            this.getInputHeaderId(SelectedHeadersEnums, 'rebateTotal'),
            this.getInputHeaderId(SelectedHeadersEnums, 'taxTotal'),
            this.getInputHeaderId(SelectedHeadersEnums, 'purchaseValueWithTax'),
        ];
        if (type !== StockDocumentTypesEnum.goodsReceivingNote) {
            readOnlyArray.push(this.getInputHeaderId(SelectedHeadersEnums, 'taxId1'));
            readOnlyArray.push(this.getInputHeaderId(SelectedHeadersEnums, 'taxId2'));
            readOnlyArray.push(this.getInputHeaderId(SelectedHeadersEnums, 'taxId3'));
        }
        return readOnlyArray;
    }

    // ZA ULAZNU KALKULACIJU

    makeEntryCalcItemModel(
        data: any,
        skuItem?: StockKeepingUnitModel,
        entryCalcHeaderId?: number
    ): EntryCalculationItemModel {
        const entryCalcItem: EntryCalculationItemModel = {
            barcode: skuItem ? skuItem.barcode : data[this.getInputHeaderId(CalculationHeadersEnums, 'barcode')],
            code: skuItem ? skuItem.code : data[this.getInputHeaderId(CalculationHeadersEnums, 'code')],
            name: skuItem ? skuItem.name : data[this.getInputHeaderId(CalculationHeadersEnums, 'name')],
            skuPrice: skuItem
                ? skuItem.salesPrice
                : data[this.getInputHeaderId(CalculationHeadersEnums, 'currentSkuPrice')],
            quantity: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'quantity')],
            invoiceUnitPrice: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'invoiceUnitPrice')],
            invoicePriceValue: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'invoicePriceValue')],
            rebatePercentage: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'rebatePercentage')],
            netoUnitPrice: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'netoUnitPrice')],
            netoPriceValue: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'netoPriceValue')],
            transportation: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'transportation')],
            shipping: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'shipping')],
            customs: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'customs')],
            otherCosts: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'otherCosts')],
            purchaseUnitPrice: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'purchaseUnitPrice')],
            purchasePriceValue: skuItem
                ? 0
                : data[this.getInputHeaderId(CalculationHeadersEnums, 'purchasePriceValue')],
            priceDifferencePercentage: skuItem
                ? 0
                : data[this.getInputHeaderId(CalculationHeadersEnums, 'priceDifferencePercentage')],
            wholesaleUnitPrice: skuItem
                ? 0
                : data[this.getInputHeaderId(CalculationHeadersEnums, 'wholesaleUnitPrice')],
            wholesalePriceValue: skuItem
                ? 0
                : data[this.getInputHeaderId(CalculationHeadersEnums, 'wholesalePriceValue')],
            tax1: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'tax1')],
            tax2: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'tax2')],
            tax3: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'tax3')],
            tax1Total: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'tax1Total')],
            tax2Total: skuItem
                ? 0
                : data[this.getInputHeaderId(CalculationHeadersEnums, 'tax2Total')]
                ? data[this.getInputHeaderId(CalculationHeadersEnums, 'tax2Total')]
                : null,
            tax3Total: skuItem
                ? 0
                : data[this.getInputHeaderId(CalculationHeadersEnums, 'tax3Total')]
                ? data[this.getInputHeaderId(CalculationHeadersEnums, 'tax3Total')]
                : null,
            retailUnitPrice: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'retailUnitPrice')],
            retailPriceValue: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'retailPriceValue')],
            entryCalcHeaderId: entryCalcHeaderId
                ? entryCalcHeaderId
                : data[this.getInputHeaderId(CalculationHeadersEnums, 'entryCalcHeaderId')],
            skuId: skuItem ? skuItem.id : data[this.getInputHeaderId(CalculationHeadersEnums, 'skuId')],
        };
        return entryCalcItem;
    }

    getReadOnlyProps(SelectedHeadersEnums: StockHeaderEnums): number[] {
        const readOnlyProps = [
            this.getInputHeaderId(SelectedHeadersEnums, 'id'),
            this.getInputHeaderId(SelectedHeadersEnums, 'currentSkuPrice'),
            this.getInputHeaderId(SelectedHeadersEnums, 'stockMeasureUnit'),
            this.getInputHeaderId(SelectedHeadersEnums, 'tax1Total'),
            this.getInputHeaderId(SelectedHeadersEnums, 'tax2Total'),
            this.getInputHeaderId(SelectedHeadersEnums, 'tax3Total'),
            this.getInputHeaderId(SelectedHeadersEnums, 'transportation'),
            this.getInputHeaderId(SelectedHeadersEnums, 'shipping'),
            this.getInputHeaderId(SelectedHeadersEnums, 'customs'),
            this.getInputHeaderId(SelectedHeadersEnums, 'otherCosts'),
            this.getInputHeaderId(SelectedHeadersEnums, 'skuId'),
            this.getInputHeaderId(SelectedHeadersEnums, 'rebateTotal'),
            this.getInputHeaderId(SelectedHeadersEnums, 'priceDifferenceValue'),
        ];
        return readOnlyProps;
    }

    makeDataForTableForCalculation(entryCalcItems: EntryCalculationItemExtended[], setData: Function): void {
        const _data: any = [];
        let rowHeaders = [];
        for (const h in CalculationHeadersEnums) {
            rowHeaders.push(translate(CalculationHeadersEnums[h]));
        }
        _data.push(rowHeaders);

        entryCalcItems.forEach((r: EntryCalculationItemExtended) => {
            let row = [];
            row.push(r.id);
            row.push(r.name ? r.name + (r.code ? ' (' + r.code + ') ' : '') : r.code ? ' (' + r.code + ')' : '');
            row.push(r.StockKeepingUnit?.stockMeasureUnit);
            row.push(r.quantity ? Number(r.quantity) : 0);
            row.push(r.invoiceUnitPrice ? Number(Number(r.invoiceUnitPrice).toFixed(4)) : 0.0);
            row.push(r.rebatePercentage ? Number(Number(r.rebatePercentage).toFixed(2)) : 0.0);
            row.push(r.netoUnitPrice ? Number(Number(r.netoUnitPrice).toFixed(4)) : 0.0);
            row.push(r.transportation ? Number(Number(r.transportation).toFixed(4)) : 0.0);
            row.push(r.shipping ? Number(Number(r.shipping).toFixed(4)) : 0.0);
            row.push(r.customs ? Number(Number(r.customs).toFixed(4)) : 0.0);
            row.push(r.otherCosts ? Number(Number(r.otherCosts).toFixed(4)) : 0.0);
            row.push(r.purchaseUnitPrice ? Number(Number(r.purchaseUnitPrice).toFixed(4)) : 0.0);
            row.push(r.priceDifferencePercentage ? Number(Number(r.priceDifferencePercentage).toFixed(4)) : 0.0);
            row.push(r.wholesaleUnitPrice ? Number(Number(r.wholesaleUnitPrice).toFixed(4)) : 0.0);
            row.push(r.tax1 ? Number(Number(r.tax1).toFixed(2)) : '');
            row.push(r.tax2 ? Number(Number(r.tax2).toFixed(2)) : '');
            row.push(r.tax3 ? Number(Number(r.tax3).toFixed(2)) : '');
            row.push(
                r.skuPrice || r.skuPrice === 0
                    ? Number(Number(r.skuPrice).toFixed(4))
                    : r.StockKeepingUnit?.salesPrice
                    ? Number(r.StockKeepingUnit.salesPrice)
                    : 0.0
            );
            row.push(r.retailUnitPrice ? Number(Number(r.retailUnitPrice).toFixed(4)) : 0.0);
            row.push(r.invoicePriceValue ? Number(Number(r.invoicePriceValue).toFixed(4)) : 0.0);
            row.push(
                Number(
                    (Number(r.rebatePercentage ? r.rebatePercentage : 0) / 100) *
                        Number(r.invoicePriceValue ? r.invoicePriceValue : 0)
                ).toFixed(4)
            );
            row.push(r.netoPriceValue ? Number(Number(r.netoPriceValue).toFixed(4)) : 0.0);
            row.push(r.purchasePriceValue ? Number(Number(r.purchasePriceValue).toFixed(4)) : 0.0);
            row.push(
                Number(
                    (Number(r.priceDifferencePercentage ? r.priceDifferencePercentage : 0) / 100) *
                        Number(r.purchasePriceValue ? r.purchasePriceValue : 0)
                ).toFixed(4)
            );
            row.push(r.wholesalePriceValue ? Number(Number(r.wholesalePriceValue).toFixed(4)) : 0.0);
            row.push(r.retailPriceValue ? Number(Number(r.retailPriceValue).toFixed(4)) : 0.0);
            row.push(r.StockKeepingUnit?.name ? r.StockKeepingUnit.name : '');
            row.push(r.StockKeepingUnit?.code ? r.StockKeepingUnit.code : '');
            row.push(r.StockKeepingUnit?.barcode ? r.StockKeepingUnit.barcode : '');
            row.push(r.StockKeepingUnit?.id ? Number(r.StockKeepingUnit.id) : '');

            _data.push(row);
        });
        setData([..._data]);
    }

    recalculateEditedItems(data: any, editedObj: EntryCalculationItemModel): EntryCalculationItemModel {
        const _i: any = { ...editedObj };
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'invoiceUnitPrice')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'invoiceUnitPrice')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'tax1Total')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'tax1Total')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'tax2Total')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'tax2Total')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'tax3Total')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'tax3Total')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'invoicePriceValue')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'invoicePriceValue')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'rebatePercentage')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'rebatePercentage')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'netoUnitPrice')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'netoUnitPrice')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'netoPriceValue')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'netoPriceValue')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'transportation')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'transportation')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'shipping')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'shipping')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'customs')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'customs')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'otherCosts')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'otherCosts')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'purchaseUnitPrice')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'purchaseUnitPrice')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'purchasePriceValue')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'purchasePriceValue')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'purchaseUnitPrice')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'purchaseUnitPrice')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'priceDifferencePercentage')]] =
            Number(data[this.getInputHeaderId(CalculationHeadersEnums, 'priceDifferencePercentage')]);
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'wholesaleUnitPrice')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'wholesaleUnitPrice')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'wholesalePriceValue')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'wholesalePriceValue')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'retailUnitPrice')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'retailUnitPrice')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'retailPriceValue')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'retailPriceValue')]
        );

        return _i;
    }

    haveCurrencyorPercentageIndexCalculation(SelectedHeadersEnums: StockHeaderEnums): {
        haveCurrencyIndex: number[];
        havePercentageIndex: number[];
    } {
        const haveCurrencyIndex = [
            this.getInputHeaderId(SelectedHeadersEnums, 'transportation'),
            this.getInputHeaderId(SelectedHeadersEnums, 'shipping'),
            this.getInputHeaderId(SelectedHeadersEnums, 'customs'),
            this.getInputHeaderId(SelectedHeadersEnums, 'otherCosts'),
            this.getInputHeaderId(SelectedHeadersEnums, 'tax1Total'),
            this.getInputHeaderId(SelectedHeadersEnums, 'tax2Total'),
            this.getInputHeaderId(SelectedHeadersEnums, 'tax3Total'),
        ];
        const havePercentageIndex = [
            this.getInputHeaderId(SelectedHeadersEnums, 'priceDifferencePercentage'),
            this.getInputHeaderId(SelectedHeadersEnums, 'tax1'),
            this.getInputHeaderId(SelectedHeadersEnums, 'tax2'),
            this.getInputHeaderId(SelectedHeadersEnums, 'tax3'),
        ];
        return { haveCurrencyIndex, havePercentageIndex };
    }

    getCalculationDataTotalValues(entryCalcItems: EntryCalculationItemExtended[]) {
        let invoicePriceValue = 0,
            rebateValue = 0,
            netoPriceValue = 0,
            totalTransportation = 0,
            totalShipping = 0,
            totalCustoms = 0,
            totalOtherCosts = 0,
            purchasePriceValue = 0,
            wholesalePriceValue = 0,
            priceDifferenceValue = 0,
            retailPriceValue = 0,
            priceDifferencePercentage = 0,
            totalAllCosts = 0,
            totalQuantity = 0,
            salePriceNoTax = 0;

        for (let i = 0; i < entryCalcItems.length; i++) {
            const d = entryCalcItems[i];

            const rebateTotalValue =
                (Number(d?.rebatePercentage) / 100) * (Number(d?.invoiceUnitPrice) * Number(d?.quantity));

            totalQuantity = totalQuantity + Number(d?.quantity);
            invoicePriceValue = invoicePriceValue + Number(d?.invoiceUnitPrice) * Number(d?.quantity);
            rebateValue = rebateValue + Number(rebateTotalValue);
            netoPriceValue = netoPriceValue + Number(d?.quantity) * Number(d?.netoUnitPrice);
            totalTransportation = totalTransportation + Number(d?.transportation) * Number(d?.quantity);
            totalShipping = totalShipping + Number(d?.shipping) * Number(d?.quantity);
            totalCustoms = totalCustoms + Number(d?.customs) * Number(d?.quantity);
            totalOtherCosts = totalOtherCosts + Number(d?.otherCosts) * Number(d?.quantity);
            purchasePriceValue = purchasePriceValue + Number(d?.purchaseUnitPrice) * Number(d?.quantity);
            wholesalePriceValue = wholesalePriceValue + Number(d?.wholesaleUnitPrice) * Number(d?.quantity);
            retailPriceValue = retailPriceValue + Number(d?.retailUnitPrice) * Number(d?.quantity);
            salePriceNoTax = salePriceNoTax + Number((Number(d?.tax1) / 100) * Number(d?.wholesalePriceValue));
        }

        priceDifferenceValue = Number(wholesalePriceValue) - Number(purchasePriceValue);
        priceDifferencePercentage =
            (Number(priceDifferenceValue) / Number(purchasePriceValue ? purchasePriceValue : 1)) * 100;
        totalAllCosts =
            Number(totalTransportation) + Number(totalShipping) + Number(totalCustoms) + Number(totalOtherCosts);

        const totalValues = {
            invoicePriceValue: Number(invoicePriceValue)?.toFixed(2),
            rebateValue: Number(rebateValue)?.toFixed(2),
            netoPriceValue: Number(netoPriceValue)?.toFixed(2),
            totalTransportation: Number(totalTransportation)?.toFixed(2),
            totalShipping: Number(totalShipping)?.toFixed(2),
            totalCustoms: Number(totalCustoms)?.toFixed(2),
            totalOtherCosts: Number(totalOtherCosts)?.toFixed(2),
            purchasePriceValue: Number(purchasePriceValue)?.toFixed(2),
            wholesalePriceValue: Number(wholesalePriceValue)?.toFixed(2),
            priceDifferenceValue: Number(priceDifferenceValue)?.toFixed(2),
            retailPriceValue: Number(retailPriceValue)?.toFixed(2),
            priceDifferencePercentage: Number(priceDifferencePercentage)?.toFixed(2),
            totalAllCosts: Number(totalAllCosts)?.toFixed(2),
            totalQuantity: Number(totalQuantity)?.toFixed(2),
            salePriceNoTax: Number(salePriceNoTax)?.toFixed(2),
        };

        return totalValues;
    }

    //on change funkcije za dokumente i kalkulaciju
    async onChangeDocumentCells(
        changes: SheetChange[],
        data: any,
        stockDocItems: StockDocItemsExtended[],
        _editedStockDocItems: { [key: string]: StockDocItemsExtended },
        SelectedHeadersEnums: StockHeaderEnums,
        taxes: TaxModel[],
        _HeaderEnums: StockHeaderEnums,
        selectValue?: string | number
    ): Promise<any> {
        let newData: any = [...data],
            _stockDocItems: StockDocItemsExtended[] = [...stockDocItems];
        let editedStockDocItems: { [key: string]: StockDocItemsExtended } = { ..._editedStockDocItems },
            selectedSkuItem: StockKeepingUnitModel = {} as StockKeepingUnitModel;
        const last = Object.keys(SelectedHeadersEnums)[Object.keys(SelectedHeadersEnums).length - 1];

        if (changes.length > 1) {
            if (changes.every((x: SheetChange) => x.value === null || x.value === '')) {
                //izbirsan row
                const itemExist = stockDocItems[changes[0].y]
                    ? { ...stockDocItems[changes[0].y] }
                    : editedStockDocItems?.[changes[0].y]
                    ? { ...editedStockDocItems?.[changes[0].y] }
                    : null;

                if (itemExist?.id && stockDocItems?.[changes[0].y] && !itemExist?.lockedItem) {
                    const _itemToDel = { ...itemExist };
                    _itemToDel.delete = true;
                    editedStockDocItems[changes[0].y] = _itemToDel;
                    _stockDocItems = stockDocItems.filter((i) => i.id !== itemExist.id);
                }
                Object.keys(_HeaderEnums).forEach((key) => (newData[changes[0].y][key] = ''));
            } else if (changes.every((change: SheetChange) => change.x === changes[0].x) && changes?.length > 1) {
                //copy paste po stupcu
                for (const change of changes) {
                    const _editItem: any = editedStockDocItems?.[change.y]
                        ? { ...editedStockDocItems?.[change.y] }
                        : _stockDocItems[change.y]
                        ? { ..._stockDocItems[change.y] }
                        : null;

                    if (_editItem && !_editItem?.lockedItem) {
                        const inputCell = Object.keys(_HeaderEnums).find(
                            (h) => _HeaderEnums[Number(h)] === SelectedHeadersEnums[change.x]
                        );
                        if (inputCell && newData[change.y]) {
                            change.value = this.returnEditStockDocItemValue(change, SelectedHeadersEnums, taxes);
                            _editItem[
                                change.x === this.getInputHeaderId(SelectedHeadersEnums, 'stockUnitQuantity')
                                    ? 'quantity'
                                    : change.x === this.getInputHeaderId(SelectedHeadersEnums, 'purchaseUnitPrice')
                                    ? 'purchasePrice'
                                    : _HeaderEnums[Number(inputCell)]
                            ] = change.value;

                            editedStockDocItems[change.y] = _editItem;
                            newData[change.y][inputCell] = change.value;
                            newData = this.recalculateDocumentsNewData(
                                newData,
                                change,
                                SelectedHeadersEnums,
                                _HeaderEnums,
                                taxes
                            );
                        }
                    }
                }
            }
        } else {
            for (const change of changes) {
                let _editItem: any = _stockDocItems[change.y] ? { ..._stockDocItems[change.y] } : null;
                if (change.x === this.getInputHeaderId(SelectedHeadersEnums, 'skuItem') && change.value !== '') {
                    if (!newData[change.y]) {
                        newData[change.y] = [];
                        // eslint-disable-next-line
                        Object.keys(_HeaderEnums).forEach((key) => {
                            newData[change.y][key] = '';
                        });
                    }

                    if (_editItem) {
                        throw new Error(translate('You can not change sku item!'));
                    } else {
                        const sameItemExist = editedStockDocItems?.[change.y] ? true : false;
                        let _change: SheetChange = {
                            x: change.x,
                            y: change.y,
                            value: selectValue ? selectValue : change.value,
                        };
                        if (sameItemExist) {
                            throw new Error(translate('You already added that sku item!'));
                        } else if (change?.skuItem?.id) {
                            _change.skuItem = { ...change.skuItem };
                            newData[change.y] = this.changeCodeOrBarcodeCell(
                                newData,
                                _change,
                                _HeaderEnums,
                                true,
                                taxes
                            );
                            const _newStockDocItem: StockDocItemsExtended = {
                                skuId: change?.skuItem?.id,
                                quantity: 0,
                                purchasePrice: 0,
                                taxId1: null,
                                taxId2: null,
                                taxId3: null,
                                skuPrice: Number(change?.skuItem?.salesPrice),
                                priceForOfferNote: Number(change?.skuItem?.salesPrice),
                                skuName: change?.skuItem?.name,
                                lockedItem: false,
                                StockKeepingUnit: { ...change?.skuItem },
                            };
                            editedStockDocItems[change.y] = _newStockDocItem;
                        } else {
                            newData = [...data];
                        }
                    }
                } else if (
                    change.x >= this.getInputHeaderId(SelectedHeadersEnums, 'stockUnitQuantity') &&
                    change.x <= Number(last) &&
                    change.value !== ''
                ) {
                    //ostala polja koja se popunjaju
                    if (!newData[change.y]) {
                        throw new Error(translate('Please add code first!'));
                    }

                    const inputCell = Object.keys(_HeaderEnums).find(
                        (h) => _HeaderEnums[Number(h)] === SelectedHeadersEnums[change.x]
                    );

                    if (inputCell && newData[change.y][Number(this.getInputHeaderId(_HeaderEnums, 'skuItem'))] !== '') {
                        change.value = this.returnEditStockDocItemValue(change, SelectedHeadersEnums, taxes);

                        const isPiecesQtyCell =
                            change.x === this.getInputHeaderId(SelectedHeadersEnums, 'piecesQuantity');

                        newData[change.y][Number(inputCell)] =
                            change.x >= this.getInputHeaderId(SelectedHeadersEnums, 'stockUnitQuantity') &&
                            (change.x <= this.getInputHeaderId(SelectedHeadersEnums, 'purchaseValueStockUnit') ||
                                change.x <= this.getInputHeaderId(SelectedHeadersEnums, 'itemValue') ||
                                change.x <= this.getInputHeaderId(SelectedHeadersEnums, 'skuId'))
                                ? !Number(change.value)
                                    ? 0
                                    : Number(change.value)
                                : change.value;

                        newData = this.recalculateDocumentsNewData(
                            newData,
                            change,
                            SelectedHeadersEnums,
                            _HeaderEnums,
                            taxes
                        );

                        if (!_editItem) {
                            _editItem = editedStockDocItems?.[change.y] ? { ...editedStockDocItems?.[change.y] } : null;
                        }

                        if (!_editItem?.lockedItem) {
                            _editItem.edit = true;
                            //mora postojati ovdje jer prvo upisujem code
                            let alreadyInEditingArray = editedStockDocItems?.[change.y]
                                ? { ...editedStockDocItems[change.y] }
                                : null;

                            if (alreadyInEditingArray) {
                                let _i: any = { ...editedStockDocItems[change.y] };
                                if (isPiecesQtyCell) {
                                    _i['quantity'] =
                                        Number(change.value) *
                                        Number(_i.StockKeepingUnit?.purchaseMeasureUnit_stockUnitMultiplier);
                                } else {
                                    _i[
                                        change.x === this.getInputHeaderId(SelectedHeadersEnums, 'stockUnitQuantity')
                                            ? 'quantity'
                                            : change.x ===
                                              this.getInputHeaderId(SelectedHeadersEnums, 'purchaseUnitPrice')
                                            ? 'purchasePrice'
                                            : _HeaderEnums[Number(inputCell)]
                                    ] = change.value;
                                }
                                editedStockDocItems[change.y] = { ..._i };
                            } else {
                                if (isPiecesQtyCell) {
                                    _editItem['quantity'] =
                                        Number(change.value) *
                                        Number(_editItem.StockKeepingUnit?.purchaseMeasureUnit_stockUnitMultiplier);
                                } else {
                                    _editItem[
                                        change.x === this.getInputHeaderId(SelectedHeadersEnums, 'stockUnitQuantity')
                                            ? 'quantity'
                                            : change.x ===
                                              this.getInputHeaderId(SelectedHeadersEnums, 'purchaseUnitPrice')
                                            ? 'purchasePrice'
                                            : _HeaderEnums[Number(inputCell)]
                                    ] = change.value;
                                }
                                editedStockDocItems[change.y] = _editItem;
                            }
                        }
                    }
                }
            }
        }

        return { newData, editedStockDocItems, selectedSkuItem };
    }

    recalculateDocumentsNewData(
        data: any,
        change: SheetChange,
        SelectedHeadersEnums: StockHeaderEnums,
        _HeaderEnums: StockHeaderEnums,
        taxes: TaxModel[]
    ) {
        let newData = [...data];
        const isPiecesQtyCell = change.x === this.getInputHeaderId(SelectedHeadersEnums, 'piecesQuantity');
        const isQuantityyCell = change.x === this.getInputHeaderId(SelectedHeadersEnums, 'stockUnitQuantity');
        const isUnitPriceCell = change.x === this.getInputHeaderId(SelectedHeadersEnums, 'purchaseUnitPrice');

        if (
            change?.x === this.getInputHeaderId(SelectedHeadersEnums, 'taxId1') ||
            change?.x === this.getInputHeaderId(SelectedHeadersEnums, 'taxId2') ||
            change?.x === this.getInputHeaderId(SelectedHeadersEnums, 'taxId3')
        ) {
            const _tax = taxes?.find((t) => t?.id === change?.value);
            if (_tax) {
                if (change?.x === this.getInputHeaderId(SelectedHeadersEnums, 'taxId1')) {
                    newData[change.y][this.getInputHeaderId(_HeaderEnums, 'taxId1')] = _tax?.name;
                } else if (change?.x === this.getInputHeaderId(SelectedHeadersEnums, 'taxId2')) {
                    newData[change.y][this.getInputHeaderId(_HeaderEnums, 'taxId2')] = _tax?.name;
                } else if (change?.x === this.getInputHeaderId(SelectedHeadersEnums, 'taxId3')) {
                    newData[change.y][this.getInputHeaderId(_HeaderEnums, 'taxId3')] = _tax?.name;
                }
            }
        }

        const taxId1 = this.findTaxId(newData[change.y][this.getInputHeaderId(_HeaderEnums, 'taxId1')], taxes);
        const taxId2 = this.findTaxId(newData[change.y][this.getInputHeaderId(_HeaderEnums, 'taxId2')], taxes);
        const taxId3 = this.findTaxId(newData[change.y][this.getInputHeaderId(_HeaderEnums, 'taxId3')], taxes);

        if (isPiecesQtyCell) {
            newData[change.y][this.getInputHeaderId(_HeaderEnums, 'stockUnitQuantity')] =
                Number(change.value) *
                Number(
                    newData[change.y][this.getInputHeaderId(_HeaderEnums, 'purchaseMeasureUnit_stockUnitMultiplier')]
                );
            newData[change.y][this.getInputHeaderId(_HeaderEnums, 'purchaseValueStockUnit')] =
                newData[change.y][this.getInputHeaderId(_HeaderEnums, 'stockUnitQuantity')] *
                Number(newData[change.y][this.getInputHeaderId(_HeaderEnums, 'purchaseUnitPrice')]);
        } else if (isQuantityyCell) {
            newData[change.y][this.getInputHeaderId(_HeaderEnums, 'piecesQuantity')] =
                Number(
                    newData[change.y][this.getInputHeaderId(_HeaderEnums, 'purchaseMeasureUnit_stockUnitMultiplier')]
                ) !== 0
                    ? Number(change.value) /
                      Number(
                          newData[change.y][
                              this.getInputHeaderId(_HeaderEnums, 'purchaseMeasureUnit_stockUnitMultiplier')
                          ]
                      )
                    : 0;

            newData[change.y][this.getInputHeaderId(_HeaderEnums, 'purchaseValueStockUnit')] =
                Number(Number(change.value) *
                Number(newData[change.y][this.getInputHeaderId(_HeaderEnums, 'purchaseUnitPrice')]))?.toFixed(2);
            newData[change.y][this.getInputHeaderId(_HeaderEnums, 'salesPriceValue')] =
                Number(change.value) * Number(newData[change.y][this.getInputHeaderId(_HeaderEnums, 'skuPrice')]);
        }

        if (isUnitPriceCell) {
            newData[change.y][this.getInputHeaderId(_HeaderEnums, 'purchaseValueStockUnit')] =
                Number(Number(change.value) *
                Number(newData[change.y][this.getInputHeaderId(_HeaderEnums, 'stockUnitQuantity')]))?.toFixed(2);
        }

        const totalPriceValue = this.getInputHeaderId(_HeaderEnums, 'purchaseValueStockUnit')
            ? Number(newData[change.y][this.getInputHeaderId(_HeaderEnums, 'purchaseValueStockUnit')])
            : Number(newData[change.y][this.getInputHeaderId(_HeaderEnums, 'salesPriceValue')]);

        const taxTotal =
            (Number(taxId1?.percentageAmount ? taxId1?.percentageAmount : 0) / 100) * Number(totalPriceValue) +
            (Number(taxId2?.percentageAmount ? taxId2?.percentageAmount : 0) / 100) * Number(totalPriceValue) +
            (Number(taxId3?.percentageAmount ? taxId3?.percentageAmount : 0) / 100) * Number(totalPriceValue);

        newData[change.y][this.getInputHeaderId(_HeaderEnums, 'taxTotal')] = Number(taxTotal)?.toFixed(2);
        newData[change.y][this.getInputHeaderId(_HeaderEnums, 'rebateTotal')] = Number(
            (Number(newData[change.y][this.getInputHeaderId(_HeaderEnums, 'rebatePercentage')]) / 100) *
                Number(totalPriceValue)
        )?.toFixed(2);

        newData[change.y][this.getInputHeaderId(_HeaderEnums, 'purchaseValueWithTax')] = Number(
            Number(taxTotal) + Number(totalPriceValue)
        )?.toFixed(2);

        return newData;
    }

    async onChangeCalculationCell(
        changes: SheetChange[],
        data: any[],
        entryCalcItems: EntryCalculationItemExtended[],
        SelectedHeadersEnums: StockHeaderEnums,
        selectValue?: number | null
    ): Promise<any> {
        let newData = [...data],
            editedColumn = null,
            deleteRow: number | null = null,
            _entryCalcItems: EntryCalculationItemModel[] = [...entryCalcItems];
        let _editedEntryCalcItems: EntryCalculationItemModel[] = [];
        const last = Object.keys(SelectedHeadersEnums)[Object.keys(SelectedHeadersEnums).length - 1];

        if (changes.length > 1) {
            //znaci da je izbrisan row jer mozemo editirati samo do 19og stupca
            if (changes.every((x: SheetChange) => x.value === null || x.value === '')) {
                const itemExist = entryCalcItems[changes[0].y - 1] ? { ...entryCalcItems[changes[0].y - 1] } : null;
                if (itemExist?.id && !_editedEntryCalcItems.find((i) => i.id === itemExist.id)) {
                    const _itemToDel = { ...itemExist };
                    _itemToDel.delete = true;
                    _editedEntryCalcItems.push(_itemToDel);
                    _entryCalcItems = entryCalcItems.filter((i) => i.id !== itemExist.id);
                }
                deleteRow = changes[0].y;
            } else if (changes.every((change: SheetChange) => change.x === changes[0].x)) {
                //copy paste po stupcu
                editedColumn = SelectedHeadersEnums[changes[0].x];
                for (const change of changes) {
                    const _editItem: any = _entryCalcItems[change.y - 1] ? { ..._entryCalcItems[change.y - 1] } : null;
                    if (_editItem) {
                        const inputCell = Object.keys(CalculationHeadersEnums).find(
                            (h) => CalculationHeadersEnums[Number(h)] === SelectedHeadersEnums[change.x]
                        );
                        const { havePercentageIndex } =
                            this.haveCurrencyorPercentageIndexCalculation(SelectedHeadersEnums);
                        const percInd = havePercentageIndex.includes(change.x);
                        //@ts-ignore
                        const splitedValue = percInd ? change?.value?.split?.(' %') : [change.value];
                        if (
                            inputCell &&
                            newData[change.y] &&
                            Number(inputCell) !== this.getInputHeaderId(SelectedHeadersEnums, 'skuItem')
                        ) {
                            newData[change.y][inputCell] = splitedValue[0];
                            _editItem[CalculationHeadersEnums[Number(inputCell)]] = splitedValue[0];
                            _editedEntryCalcItems.push(_editItem);
                        }
                    }
                }
            }
        } else {
            // changes.length === 1)
            for (let change of changes) {
                const _editItem: any = _entryCalcItems[change.y - 1] ? { ..._entryCalcItems[change.y - 1] } : null;
                const { havePercentageIndex } = this.haveCurrencyorPercentageIndexCalculation(SelectedHeadersEnums);
                const percInd = havePercentageIndex.includes(change.x);
                //@ts-ignore
                const splitedValue = percInd ? change?.value?.split?.(' %') : [change.value];

                if (change.x === this.getInputHeaderId(SelectedHeadersEnums, 'skuItem') && splitedValue[0] !== '') {
                    if (!newData[change.y]) {
                        newData[change.y] = [];
                    }

                    if (_editItem) {
                        throw new Error(translate('You can not change sku item!'));
                    } else {
                        //barcode polje
                        let _change: SheetChange = {
                            x: change.x,
                            y: change.y,
                            value: selectValue ? selectValue : splitedValue[0],
                        };
                        try {
                            if (change?.skuItem) {
                                const itemExist = _entryCalcItems?.find((i) => i.skuId === change?.skuItem?.id);
                                if (itemExist) {
                                    newData = [...data];
                                    throw new Error(translate('You already added sku item!'));
                                } else {
                                    _change.skuItem = { ...change.skuItem };
                                    newData[change.y] = this.changeCodeOrBarcodeCell(
                                        newData,
                                        _change,
                                        CalculationHeadersEnums,
                                        false
                                    );
                                    editedColumn = 'skuId';
                                }
                            } else {
                                newData = [...data];
                            }
                        } catch (error: any) {
                            throw new Error(error);
                        }
                    }
                } else if (
                    change.x >= this.getInputHeaderId(SelectedHeadersEnums, 'skuItem') &&
                    change.x <= Number(last) &&
                    splitedValue[0] !== ''
                ) {
                    //ostala polja koja se popunjuju
                    if (!newData[change.y]) {
                        throw new Error(translate('Please add code first!'));
                    }
                    const inputCell = Object.keys(CalculationHeadersEnums).find(
                        (h) => CalculationHeadersEnums[Number(h)] === SelectedHeadersEnums[change.x]
                    );

                    editedColumn = SelectedHeadersEnums[change.x];

                    if (
                        change.x === this.getInputHeaderId(SelectedHeadersEnums, 'tax1') ||
                        change.x === this.getInputHeaderId(SelectedHeadersEnums, 'tax2') ||
                        change.x === this.getInputHeaderId(SelectedHeadersEnums, 'tax3')
                    ) {
                        // ako je neki cell od onih gdje je select option
                        if (selectValue) {
                            change.value = Number(selectValue) ? Number(selectValue) : null;
                            if (inputCell) {
                                newData[change.y][Number(inputCell)] = Number(selectValue) ? Number(selectValue) : null;

                                if (_editItem) {
                                    let alreadyInEditingArray = _editedEntryCalcItems.find((i) => i.id === _editItem.id)
                                        ? { ..._editedEntryCalcItems.find((i) => i.id === _editItem.id) }
                                        : null;
                                    if (alreadyInEditingArray) {
                                        // eslint-disable-next-line no-loop-func
                                        _editedEntryCalcItems = _editedEntryCalcItems.map((i) => {
                                            if (i.id === _editItem.id) {
                                                const _i: any = { ...i };
                                                _i[CalculationHeadersEnums[Number(inputCell)]] = Number(selectValue)
                                                    ? Number(selectValue)
                                                    : null;
                                                return _i;
                                            } else {
                                                return i;
                                            }
                                        });
                                    } else {
                                        _editItem[CalculationHeadersEnums[Number(inputCell)]] = Number(selectValue)
                                            ? Number(selectValue)
                                            : null;
                                        _editedEntryCalcItems.push(_editItem);
                                    }
                                }
                            }
                        }
                    } else {
                        // ako je obicni input
                        if (inputCell) {
                            newData[change.y][Number(inputCell)] = Number(splitedValue[0])
                                ? Number(splitedValue[0])
                                : 0.0;
                            if (_editItem) {
                                let alreadyInEditingArray = _editedEntryCalcItems.find((i) => i.id === _editItem.id)
                                    ? { ..._editedEntryCalcItems.find((i) => i.id === _editItem.id) }
                                    : null;
                                if (alreadyInEditingArray) {
                                    // eslint-disable-next-line no-loop-func
                                    _editedEntryCalcItems = _editedEntryCalcItems.map((i) => {
                                        if (i.id === _editItem.id) {
                                            const _i: any = { ...i };
                                            _i[CalculationHeadersEnums[Number(inputCell)]] = Number(splitedValue[0])
                                                ? Number(splitedValue[0])
                                                : 0.0;
                                            return _i;
                                        } else {
                                            return i;
                                        }
                                    });
                                } else {
                                    _editItem[CalculationHeadersEnums[Number(inputCell)]] =
                                        Number(change.x) >= this.getInputHeaderId(SelectedHeadersEnums, 'quantity') &&
                                        Number(splitedValue[0])
                                            ? Number(splitedValue[0])
                                            : 0.0;
                                    _editedEntryCalcItems.push(_editItem);
                                }
                            }
                        }
                    }
                }
            }
        }
        if (deleteRow !== null) {
            newData = newData.filter((r, index) => index !== deleteRow);
        }
        return { newData, _editedEntryCalcItems, editedColumn };
    }

    makeDataForTableForPriceChageLogs(priceChangeLogs: PriceChangeLogItemsExtended[], setData: Function) {
        const _data: any = [];
        priceChangeLogs.forEach((r: PriceChangeLogItemsExtended) => {
            let row = [];
            row.push(r.id);
            row.push(
                r.StockKeepingUnit?.name
                    ? r.StockKeepingUnit?.name +
                          (r.StockKeepingUnit?.code ? ' (' + r.StockKeepingUnit?.code + ')' : '') +
                          (r.StockKeepingUnit?.stockMeasureUnit ? ' ' + r.StockKeepingUnit?.stockMeasureUnit : '')
                    : '' + (r.StockKeepingUnit?.code ? ' (' + r.StockKeepingUnit?.code + ')' : '')
            );
            row.push(r.StockKeepingUnit?.stockMeasureUnit);
            row.push(Number(r.skuStockCurrent ? r.skuStockCurrent : 0)?.toFixed(2));
            row.push(Number(r.previousSkuPrice ? r.previousSkuPrice : 0)?.toFixed(2));
            row.push(Number(r.previousSkuPriceValue ? r.previousSkuPriceValue : 0)?.toFixed(2));
            row.push(Number(r.newSkuPrice ? r.newSkuPrice : 0)?.toFixed(2));
            row.push(Number(r.newSkuPriceValue ? r.newSkuPriceValue : 0)?.toFixed(2));
            row.push(Number(r.taxAdjustment ? r.taxAdjustment : 0)?.toFixed(2));
            row.push(Number(r.priceAdjustment ? r.priceAdjustment : 0)?.toFixed(2));
            row.push(r.skuId);

            _data.push(row);
        });
        setData([..._data]);
    }

    makePriceChangeLogItemModel(
        data: any,
        _HeaderEnums: StockHeaderEnums,
        selectedSkuItem: StockKeepingUnitModel,
        priceChangeLogHeaderId: number
    ): PriceChangeLogItemModel {
        const priceChangeLogItem: PriceChangeLogItemModel = {
            skuId: data[this.getInputHeaderId(_HeaderEnums, 'skuId')],
            skuName: selectedSkuItem?.name ? selectedSkuItem?.name : '',
            skuStockCurrent: Number(data[this.getInputHeaderId(_HeaderEnums, 'skuStockCurrent')])
                ? data[this.getInputHeaderId(_HeaderEnums, 'skuStockCurrent')]
                : 0,
            previousSkuPrice: Number(data[this.getInputHeaderId(_HeaderEnums, 'previousSkuPrice')])
                ? data[this.getInputHeaderId(_HeaderEnums, 'previousSkuPrice')]
                : 0,
            previousSkuPriceValue: Number(data[this.getInputHeaderId(_HeaderEnums, 'previousSkuPriceValue')])
                ? data[this.getInputHeaderId(_HeaderEnums, 'previousSkuPriceValue')]
                : 0,
            newSkuPrice: Number(data[this.getInputHeaderId(_HeaderEnums, 'newSkuPrice')])
                ? data[this.getInputHeaderId(_HeaderEnums, 'newSkuPrice')]
                : 0,
            newSkuPriceValue: Number(data[this.getInputHeaderId(_HeaderEnums, 'newSkuPriceValue')])
                ? data[this.getInputHeaderId(_HeaderEnums, 'newSkuPriceValue')]
                : 0,
            taxAdjustment: Number(data[this.getInputHeaderId(_HeaderEnums, 'taxAdjustment')])
                ? data[this.getInputHeaderId(_HeaderEnums, 'taxAdjustment')]
                : 0,
            priceAdjustment: Number(data[this.getInputHeaderId(_HeaderEnums, 'priceAdjustment')])
                ? data[this.getInputHeaderId(_HeaderEnums, 'priceAdjustment')]
                : 0,
            priceChangeLogHeaderId: priceChangeLogHeaderId,
        };
        return priceChangeLogItem;
    }

    makePriceLogItemModelBySelectedSkuItems(
        selectedSkuItem: StockKeepingUnitEditModel,
        priceChangeLogHeaderId: number
    ): PriceChangeLogItemModel {
        const priceLogItem: PriceChangeLogItemModel = {
            skuId: selectedSkuItem?.id,
            skuName: selectedSkuItem?.name,
            skuStockCurrent: 0,
            previousSkuPrice: selectedSkuItem?.salesPrice,
            previousSkuPriceValue: 0,
            newSkuPrice: 0,
            newSkuPriceValue: 0,
            taxAdjustment: 0,
            priceAdjustment: 0,
            priceChangeLogHeaderId: priceChangeLogHeaderId,
            parentId: selectedSkuItem?.parentId,
        };
        return priceLogItem;
    }

    getReadOnlyPriceChangeLogProps(SelectedHeadersEnums: StockHeaderEnums) {
        const readOnlyArray = [
            this.getInputHeaderId(SelectedHeadersEnums, 'id'),
            this.getInputHeaderId(SelectedHeadersEnums, 'skuId'),
            this.getInputHeaderId(SelectedHeadersEnums, 'stockMeasureUnit'),
            this.getInputHeaderId(SelectedHeadersEnums, 'skuStockCurrent'),
            this.getInputHeaderId(SelectedHeadersEnums, 'previousSkuPrice'),
            this.getInputHeaderId(SelectedHeadersEnums, 'previousSkuPriceValue'),
            this.getInputHeaderId(SelectedHeadersEnums, 'newSkuPriceValue'),
            this.getInputHeaderId(SelectedHeadersEnums, 'taxAdjustment'),
            this.getInputHeaderId(SelectedHeadersEnums, 'priceAdjustment'),
        ];
        return readOnlyArray;
    }

    async onChangePriceChangeLogCells(
        changes: SheetChange[],
        data: any,
        priceChangeLogItems: PriceChangeLogItemsExtended[],
        SelectedHeadersEnums: StockHeaderEnums,
        priceChangeLogHeaderId: number,
        editedItems: { [key: string]: any },
        taxes: TaxModel[],
        _HeaderEnums: StockHeaderEnums,
        selectValue?: string | number
    ): Promise<any> {
        let newData = [...data],
            _priceChangeLogItem: PriceChangeLogItemsExtended[] = [...priceChangeLogItems];
        let editedPriceChangeLogItems: { [key: string]: PriceChangeLogItemsExtended } = { ...editedItems };

        if (changes.length > 1) {
            if (changes.every((x: SheetChange) => x.value === null || x.value === '')) {
                //izbirsan row
                const itemExist = priceChangeLogItems[changes[0].y]
                    ? { ...priceChangeLogItems[changes[0].y] }
                    : editedPriceChangeLogItems?.[changes[0].y]
                    ? { ...editedPriceChangeLogItems?.[changes[0].y] }
                    : null;
                if (itemExist?.id && !editedPriceChangeLogItems?.[changes[0].y]) {
                    const _itemToDel = { ...itemExist };
                    _itemToDel.delete = true;
                    editedPriceChangeLogItems[changes[0].y] = _itemToDel;
                    _priceChangeLogItem = priceChangeLogItems.filter((i) => i.id !== itemExist.id);
                }
                Object.keys(_HeaderEnums).forEach((key) => (newData[changes[0].y][key] = ''));
            } else if (changes.every((change: SheetChange) => change.x === changes[0].x)) {
                //copy paste po stupcu
                for (const change of changes) {
                    const _editItem: any = editedPriceChangeLogItems?.[change.y]
                        ? { ...editedPriceChangeLogItems?.[change.y] }
                        : _priceChangeLogItem[change.y]
                        ? { ..._priceChangeLogItem[change.y] }
                        : null;
                    if (_editItem) {
                        const inputCell = Object.keys(_HeaderEnums).find(
                            (h) => _HeaderEnums[Number(h)] === SelectedHeadersEnums[change.x]
                        );
                        if (
                            inputCell &&
                            newData[change.y] &&
                            Number(inputCell) === this.getInputHeaderId(_HeaderEnums, 'newSkuPrice')
                        ) {
                            _editItem[SelectedHeadersEnums[change.x]] = Number(change.value);
                            editedPriceChangeLogItems[change.y] = this.recalculatePriceChangeLogItem(_editItem, taxes);
                            // editedPriceChangeLogItems.push(_editItem);
                            newData[change.y][inputCell] = change.value;
                            newData[change.y][this.getInputHeaderId(_HeaderEnums, 'newSkuPriceValue')] =
                                editedPriceChangeLogItems[change.y]?.newSkuPriceValue;
                            newData[change.y][this.getInputHeaderId(_HeaderEnums, 'taxAdjustment')] =
                                editedPriceChangeLogItems[change.y]?.taxAdjustment;
                            newData[change.y][this.getInputHeaderId(_HeaderEnums, 'priceAdjustment')] =
                                editedPriceChangeLogItems[change.y]?.priceAdjustment;
                        }
                    }
                }
            }
        } else {
            for (const change of changes) {
                const _editItem: any = _priceChangeLogItem[change.y] ? { ..._priceChangeLogItem[change.y] } : null;

                if (change.x === this.getInputHeaderId(SelectedHeadersEnums, 'skuItem') && change.value !== '') {
                    if (!newData[change.y]) {
                        newData[change.y] = [];
                        // eslint-disable-next-line
                        Object.keys(_HeaderEnums).forEach((key) => {
                            newData[change.y][key] = '';
                        });
                    }

                    if (_editItem) {
                        throw new Error(translate('You can not change sku item!'));
                    } else {
                        let _change: SheetChange = {
                            x: change.x,
                            y: change.y,
                            value: selectValue ? selectValue : change.value,
                        };
                        if (change?.skuItem?.id) {
                            const itemExist = _priceChangeLogItem?.find((p) => p.skuId === change?.skuItem?.id);
                            if (itemExist) {
                                newData = [...data];
                                throw new Error(translate('You already added that sku item!'));
                            } else {
                                _change.skuItem = { ...change.skuItem };
                                newData[change.y] = this.changeCodeOrBarcodeCell(newData, _change, _HeaderEnums, true);
                                const _newPriceLogItem: PriceChangeLogItemsExtended = {
                                    skuId: change?.skuItem?.id,
                                    skuName: change?.skuItem?.name,
                                    skuStockCurrent: 0,
                                    previousSkuPrice: 0,
                                    previousSkuPriceValue: 0,
                                    newSkuPrice: 0,
                                    newSkuPriceValue: 0,
                                    taxAdjustment: 0,
                                    priceAdjustment: 0,
                                    priceChangeLogHeaderId: priceChangeLogHeaderId,
                                    StockKeepingUnit: { ...change?.skuItem },
                                };
                                editedPriceChangeLogItems[change.y] = _newPriceLogItem;
                            }
                        } else {
                            newData = [...data];
                        }
                    }
                } else if (
                    change.x >= this.getInputHeaderId(SelectedHeadersEnums, 'newSkuPrice') &&
                    change.value !== ''
                ) {
                    //ostala polja koja se popunjaju
                    if (!newData[change.y]) {
                        throw new Error(translate('Please add sku item first!'));
                    }

                    const _editItem: any = editedPriceChangeLogItems?.[change.y]
                        ? { ...editedPriceChangeLogItems[change.y] }
                        : _priceChangeLogItem[change.y]
                        ? { ..._priceChangeLogItem[change.y] }
                        : null;
                    if (_editItem) {
                        const inputCell = Object.keys(_HeaderEnums).find(
                            (h) => _HeaderEnums[Number(h)] === SelectedHeadersEnums[change.x]
                        );
                        if (
                            inputCell &&
                            newData[change.y] &&
                            Number(inputCell) === this.getInputHeaderId(_HeaderEnums, 'newSkuPrice')
                        ) {
                            _editItem[SelectedHeadersEnums[change.x]] = Number(change.value);
                            editedPriceChangeLogItems[change.y] = this.recalculatePriceChangeLogItem(_editItem, taxes);
                            // editedPriceChangeLogItems.push(_editItem);
                            newData[change.y][inputCell] = change.value;
                            newData[change.y][this.getInputHeaderId(_HeaderEnums, 'newSkuPriceValue')] =
                                editedPriceChangeLogItems[change.y]?.newSkuPriceValue;
                            newData[change.y][this.getInputHeaderId(_HeaderEnums, 'taxAdjustment')] =
                                editedPriceChangeLogItems[change.y]?.taxAdjustment;
                            newData[change.y][this.getInputHeaderId(_HeaderEnums, 'priceAdjustment')] =
                                editedPriceChangeLogItems[change.y]?.priceAdjustment;
                        }
                    }
                }
            }
        }

        return { newData, editedPriceChangeLogItems };
    }

    recalculatePriceChangeLogItem(editedItem: PriceChangeLogItemsExtended, taxes: TaxModel[]) {
        let _priceChangeLogItem = { ...editedItem };
        _priceChangeLogItem.newSkuPriceValue =
            Number(_priceChangeLogItem.skuStockCurrent) * Number(_priceChangeLogItem.newSkuPrice);

        const skuItemTax1 = taxes?.find((t) => t?.id === editedItem?.StockKeepingUnit?.taxId1);
        const skuItemTax2 = taxes?.find((t) => t?.id === editedItem?.StockKeepingUnit?.taxId2);
        const skuItemTax3 = taxes?.find((t) => t?.id === editedItem?.StockKeepingUnit?.taxId3);

        const tax1 = skuItemTax1?.percentageAmount ? skuItemTax1.percentageAmount : 0.0;
        const tax2 = skuItemTax2?.percentageAmount ? skuItemTax2.percentageAmount : null;
        const tax3 = skuItemTax3?.percentageAmount ? skuItemTax3.percentageAmount : null;
        const priceDiff =
            Number(_priceChangeLogItem.previousSkuPriceValue) - Number(_priceChangeLogItem.newSkuPriceValue);
        const taxAdj =
            Number((Number(tax1) * Number(priceDiff)) / 100) +
            (tax2 ? Number((Number(tax2) * Number(priceDiff)) / 100) : 0) +
            (tax3 ? Number((Number(tax3) * Number(priceDiff)) / 100) : 0);

        _priceChangeLogItem.taxAdjustment = Number(taxAdj?.toFixed(2));
        _priceChangeLogItem.priceAdjustment = Number(priceDiff?.toFixed(2)) - Number(taxAdj?.toFixed(2));

        return _priceChangeLogItem;
    }
}

export const StockDocHeaderTemplate: StockDocHeadersWithItems = {
    docNumber: '',
    type: StockDocumentTypesEnum.goodsReceivingNote,
    date: new Date().getTime(),
    commitedDate: null,
    itemsCount: 0,
    itemsValue: 0,
    locked: false,
    note: null,
    createdByUser: null,
    commitedByUser: null,
    companyId: null,
    warehouseId: null,
    receivingWarehouseId: null,
    currency: null,
    Company: null,
    CreatedByUser: null,
    CommitedByUser: null,
    StockDocItems: [],
    docEntryCalcId: null,
};

export const EntryCalcHeaderTemplate: EntryCalcHeadersWithItems = {
    docNumber: '',
    stockDocHeaderId: null,
    date: new Date().getTime(),
    commitedDate: null,
    locked: false,
    currency: null,
    createdByUser: null,
    commitedByUser: null,
    companyId: null,
    totalTransportation: 0.0,
    totalShipping: 0.0,
    totalCustoms: 0.0,
    totalOtherCosts: 0.0,
    totalInvoicePriceValue: 0.0,
    CreatedByUser1: null,
    CommitedByUser1: null,
    StockDocHeader: null,
    EntryCalculationItems: [],
    Company: null,
    calculationInvoiceNumber: null,
    newPriceValidFrom: null,
};

export const PriceChangeLogsHeaderWithItems: PriceChangeLogHeaderWithItems = {
    docNumber: '',
    date: new Date().getTime(),
    commitedDate: null,
    createdByUser: null,
    commitedByUser: null,
    newPriceValidFrom: new Date().getTime(),
    currency: null,
    locked: false,
    warehouseId: null,
    note: null,
    PriceChangeLogItem: [],
    CreatedByUser: null,
    CommitedByUser: null,
};

export const GoodsReceivingNoteHeadersEnums: StockHeaderEnums = {
    0: 'id',
    1: 'skuItem',
    2: 'stockMeasureUnit',
    3: 'purchaseMeasureUnit',
    4: 'purchaseMeasureUnit_stockUnitMultiplier',
    5: 'skuPrice',
    6: 'stockUnitQuantity',
    7: 'piecesQuantity',
    8: 'purchaseUnitPrice',
    9: 'purchaseValueStockUnit',
    10: 'rebatePercentage',
    11: 'rebateTotal',
    12: 'taxId1',
    13: 'taxId2',
    14: 'taxId3',
    15: 'taxTotal',
    16: 'purchaseValueWithTax',
    17: 'skuId',
};

export const GoodsDespatchNoteHeadersEnums: StockHeaderEnums = {
    0: 'id',
    1: 'skuItem',
    2: 'stockMeasureUnit',
    3: 'skuPrice',
    4: 'stockUnitQuantity',
    5: 'taxId1',
    6: 'taxId2',
    7: 'taxId3',
    8: 'skuId',
};

export const WriteOffGoodsHeadersEnums: StockHeaderEnums = {
    0: 'id',
    1: 'skuItem',
    2: 'skuPrice',
    3: 'stockUnitQuantity',
    4: 'skuId',
};

export const OfferNoteHeadersEnums: StockHeaderEnums = {
    0: 'id',
    1: 'skuItem',
    2: 'stockMeasureUnit',
    3: 'skuPrice',
    4: 'stockUnitQuantity',
    5: 'priceForOfferNote',
    6: 'salesPriceValue',
    7: 'rebatePercentage',
    8: 'rebateTotal',
    9: 'taxId1',
    10: 'taxId2',
    11: 'taxId3',
    12: 'taxTotal',
    13: 'skuId',
};

export const InterWarehouseTransferHeadersEnums: StockHeaderEnums = {
    0: 'id',
    1: 'skuItem',
    2: 'stockUnitQuantity',
    3: 'skuId',
};

export const ProcurementOrderHeaderEnums: StockHeaderEnums = {
    0: 'id',
    1: 'skuItem',
    2: 'externalSkuCode',
    3: 'purchasePrice',
    4: 'stockUnitQuantity',
    5: 'skuId',
};

export const AggregatedStockDocHeaderEnums: StockHeaderEnums = {
    0: 'id',
    1: 'skuItem',
    4: 'skuPrice',
    6: 'stockUnitQuantity',
    7: 'stockValue',
    8: 'skuId',
};

export const CalculationHeadersEnums: StockHeaderEnums = {
    0: 'id',
    1: 'skuItem',
    2: 'stockMeasureUnit',
    3: 'quantity',
    4: 'invoiceUnitPrice',
    5: 'rebatePercentage',
    6: 'netoUnitPrice',
    7: 'transportation',
    8: 'shipping',
    9: 'customs',
    10: 'otherCosts',
    11: 'purchaseUnitPrice',
    12: 'priceDifferencePercentage',
    13: 'wholesaleUnitPrice',
    14: 'tax1',
    15: 'tax2',
    16: 'tax3',
    17: 'currentSkuPrice',
    18: 'retailUnitPrice',
    19: 'invoicePriceValue',
    20: 'rebateTotal',
    21: 'netoPriceValue',
    22: 'purchasePriceValue',
    23: 'priceDifferencePercentage',
    24: 'wholesalePriceValue',
    25: 'retailPriceValue',
    26: 'name',
    27: 'code',
    28: 'barcode',
    29: 'skuId',
};

export const InventoryHeadersEnums: StockHeaderEnums = {
    0: 'skuId',
    1: 'skuItem',
    2: 'previousState',
    3: 'totalGoodsReceived',
    4: 'totalGoodsDespatched',
    5: 'totalInovicedStock',
    6: 'manualTotalInovicedStock',
    7: 'totalCumulativeLoss',
    8: 'accountingState',
    9: 'goodsReceiving',
    10: 'stateWithGoodsReceiving',
    11: 'inventoryState',
    12: 'surplusState',
    13: 'deficitState',
    14: 'skuPrice',
    15: 'stockPriceValue',
    16: 'stockDocItemId',
};

export const SupplierPriceListHeaderEnums: StockHeaderEnums = {
    0: 'skuItem',
    1: 'supplierSkuCode',
    2: 'previousPrice',
    3: 'supplierProcurementPrice',
    4: 'skuId',
    5: 'internalSkuCode',
    6: 'internalSkuBarcode',
    7: 'skuItemName',
};

export const PriceChangeLogsHeadersEnums: StockHeaderEnums = {
    0: 'id',
    1: 'skuItem',
    2: 'stockMeasureUnit',
    3: 'skuStockCurrent',
    4: 'previousSkuPrice',
    5: 'previousSkuPriceValue',
    6: 'newSkuPrice',
    7: 'newSkuPriceValue',
    8: 'taxAdjustment',
    9: 'priceAdjustment',
    10: 'skuId',
};

export { StockController };
